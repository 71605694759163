var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.entry)?_c('div',{staticClass:"week-day-entry is-unselectable",staticStyle:{"border-left":"6px solid white"},style:({ 'border-left-color': _vm.entry.color })},[_c('div',{staticClass:"week-day-entry-content"},[_c('div',{staticClass:"level is-mobile"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item"},[_c('span',{staticClass:"icon"},[_c('fa',{attrs:{"icon":['fal', _vm.entry.icon],"fixed-width":""}})],1)]),_c('div',{staticClass:"level-item"},[_vm._v(_vm._s(_vm.name))]),(_vm.entry.input === 'from-until')?[_c('div',{staticClass:"level-item is-hidden-mobile"},[_vm._v(" "+_vm._s(_vm.$t('weekday_entry_from'))+" ")]),_c('div',{staticClass:"level-item"},[_c('input-time',{attrs:{"value":_vm.entry.from,"placeholder":"entry_span_from","disabled":_vm.disabled},on:{"":function($event){},"change":function($event){return _vm.entryChange({
                  date: _vm.entry.date,
                  id: _vm.entry.id,
                  field: 'from',
                  value: $event,
                })}}})],1),_c('div',{staticClass:"level-item is-hidden-mobile"},[_vm._v(" "+_vm._s(_vm.$t('weekday_entry_until'))+" ")]),_c('div',{staticClass:"level-item"},[_c('input-time',{attrs:{"value":_vm.entry.until,"placeholder":"entry_span_until","disabled":_vm.disabled},on:{"change":function($event){return _vm.entryChange({
                  date: _vm.entry.date,
                  id: _vm.entry.id,
                  field: 'until',
                  value: $event,
                })}}})],1)]:(_vm.entry.input === 'duration')?[_c('input-duration-time',{attrs:{"value":_vm.entry.duration,"placeholder":"entry_duration_placeholder","disabled":_vm.disabled},on:{"change":function($event){return _vm.entryChange({
                date: _vm.entry.date,
                id: _vm.entry.id,
                field: 'duration',
                value: $event,
              })}}})]:(_vm.entry.input === 'day' || _vm.entry.input === 'day-percent')?[_c('div',{staticClass:"level-item"},[_c('input-day',{attrs:{"value":_vm.entry.day,"disabled":_vm.disabled},on:{"change":function($event){return _vm.entryChange({
                  date: _vm.entry.date,
                  id: _vm.entry.id,
                  field: 'day',
                  value: $event,
                })}}})],1),_c('div',{staticClass:"level-item"},[_vm._v(_vm._s(_vm.$t('weekday_entry_day')))])]:_vm._e()],2),_c('div',{staticClass:"level-right"},[(!_vm.entry.valid)?_c('div',{staticClass:"level-item"},[_c('span',{staticClass:"icon has-text-danger"},[_c('fa',{attrs:{"icon":['fal', 'exclamation-triangle']}})],1)]):_vm._e(),(_vm.entry.generated)?_c('div',{staticClass:"level-item"},[_c('span',{staticClass:"icon"},[_c('fa',{attrs:{"icon":['fal', 'magic']}})],1)]):_vm._e(),_c('div',{staticClass:"level-item"},[_c('sqr-button',{attrs:{"icon":"sticky-note","disabled":_vm.disabled,"color":_vm.entry.note ? 'info' : '',"is-outlined":!!_vm.entry.note,"tooltip":_vm.entry.note},on:{"click":function($event){_vm.noteModal = true}}})],1),(_vm.canRemove)?_c('div',{staticClass:"level-item"},[_c('sqr-button',{attrs:{"icon":"trash","disabled":_vm.disabled},on:{"click":function($event){return _vm.entryRemove({ date: _vm.entry.date, id: _vm.entry.id })}}})],1):_vm._e()])])]),_c('note-modal',{attrs:{"label":"entry_note","show":_vm.noteModal,"value":_vm.entry.note},on:{"change":function($event){return _vm.entryChange({
        date: _vm.entry.date,
        id: _vm.entry.id,
        field: 'note',
        value: $event,
      })},"close":function($event){_vm.noteModal = false}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }