<template>
  <div>
    <label class="label" :class="labelClass" v-if="labelTr">{{
      labelTr
    }}</label>
    <div class="control" :class="controlClass" style="width: 4.5rem">
      <input
        :id="id"
        :name="name"
        :autocomplete="autocomplete"
        :placeholder="placeholderTr"
        class="input"
        :class="inputClass"
        type="number"
        :min="min"
        :step="step"
        :max="max"
        :aria-describedby="nameDesc"
        :value="value"
        @input="input"
        @change="change"
        :disabled="disabled"
      />
      <span class="icon is-small is-left" v-if="icon"
        ><i class="fa" :class="iconClass"></i
      ></span>
      <span class="icon is-small is-right" v-if="valid"
        ><i class="fal fa-check"></i
      ></span>
      <p class="help" v-if="helper">{{ helperTr }}</p>
    </div>
  </div>
</template>

<style scoped></style>

<script>
import input from '../sqrd/mixins/input';

export default {
  name: 'InputDay',
  mixins: [input],
  props: {
    value: { type: [Number, String] },
    min: { type: Number, default: 0 },
    step: { type: Number, default: 0.1 },
    max: { type: Number, default: 1 },
  },
  methods: {
    input(event) {
      this.$emit('input', parseFloat(event.target.value));
    },
    change(event) {
      this.$emit('change', parseFloat(event.target.value));
    },
  },
};
</script>
