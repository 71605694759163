<template>
  <div>
    <label class="label" :class="labelClass" v-if="labelTr">{{
      labelTr
    }}</label>
    <div class="control" :class="controlClass">
      <textarea
        :id="id"
        :name="name"
        :autocomplete="autocomplete"
        :placeholder="placeholderTr"
        class="textarea"
        :rows="rows"
        :class="inputClass"
        :aria-describedby="nameDesc"
        :value="value"
        @input="input"
        @change="change"
        :disabled="disabled"
        v-on="listeners"
      ></textarea>
      <span class="icon is-small is-left" v-if="icon"
        ><i class="fa" :class="iconClass"></i
      ></span>
      <span class="icon is-small is-right" v-if="valid"
        ><i class="fal fa-check"></i
      ></span>
    </div>
    <p class="help" v-if="helper">{{ helperTr }}</p>
  </div>
</template>

<script>
import input from './mixins/input';

export default {
  name: 'sqr-input-textarea',
  mixins: [input],
  props: {
    rows: { type: Number, default: 3 },
  },
};
</script>
