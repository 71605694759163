<template>
  <div>
    <label class="label" :class="labelClass" v-if="labelTr">{{
      labelTr
    }}</label>
    <div class="control" :class="controlClass" style="width: 6.5rem">
      <input
        :id="id"
        :name="name"
        :autocomplete="autocomplete"
        :placeholder="placeholderTr"
        class="input"
        :class="inputClass"
        type="text"
        :aria-describedby="nameDesc"
        :value="valueFormated"
        @input="input"
        @change="change"
        :disabled="disabled"
      />
      <span class="icon is-small is-left" v-if="icon"
        ><fa :icon="['fal', icon]"
      /></span>
      <span class="icon is-small is-right" v-if="valid"
        ><fa :icon="['fal', 'check']"
      /></span>
      <p class="help" v-if="helper">{{ helperTr }}</p>
    </div>
  </div>
</template>

<script>
import { Duration } from 'luxon';
import input from '@/sqrd/mixins/input';

export default {
  name: 'InputDurationTime',
  mixins: [input],
  model: { prop: 'value', event: 'change' },
  props: {
    guess: { type: String, default: '' },
  },
  computed: {
    valueFormated() {
      if (!this.value) {
        return '';
      } else {
        return Duration.fromISO(this.value)
          .shiftTo('hours', 'minutes')
          .toFormat('hh:mm');
      }
    },
  },
  methods: {
    parse(event) {
      let value = event.target.value;
      let duration;
      if (!value) {
        duration = Duration.fromMillis(0);
      } else {
        let parts = value.split(':');
        if (parts.length === 1) {
          let decimals = parts[0].split('.');
          if (decimals.length === 2) {
            let hours = parseInt(decimals[0]);
            let minutes = Math.round(parseFloat('0.' + decimals[1]) * 60);
            duration = Duration.fromObject({ hours, minutes });
          } else if (this.guess === 'hh') {
            duration = Duration.fromObject({ hours: parseInt(parts[0]) });
          } else if (this.guess === 'mm') {
            duration = Duration.fromObject({ minutes: parseInt(parts[0]) });
          } else if (parseInt(parts[0]) < 3) {
            duration = Duration.fromObject({ hours: parseInt(parts[0]) });
          } else {
            duration = Duration.fromObject({ minutes: parseInt(parts[0]) });
          }
        } else if (parts.length === 2) {
          duration = Duration.fromObject({
            hours: parseInt(parts[0]),
            minutes: parseInt(parts[1]),
          });
        } else {
          duration = Duration.fromMillis(0);
        }
      }
      return duration.toISO();
    },
    input(event) {
      this.$emit('input', this.parse(event));
    },
    change(event) {
      this.$emit('change', this.parse(event));
    },
  },
};
</script>
