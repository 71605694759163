<template>
  <div class="modal" :class="modalClass">
    <div class="modal-background" @click="close()"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ labelTr }}</p>
        <button class="delete" aria-label="close" @click="close()"></button>
      </header>
      <div class="modal-card-body">
        <div class="field">
          <sqr-input-textarea
            label="entry_note"
            :value="value"
            @change="$emit('change', $event)"
            @keydown.meta.enter="
              $emit('change', $event.target.value);
              close();
            "
          />
        </div>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="close()"
    ></button>
  </div>
</template>

<script>
import modal from '../sqrd/mixins/modal';
import input from '../sqrd/mixins/input';
import SqrInputTextarea from '../sqrd/SqrInputTextarea';

export default {
  name: 'NoteModal',
  components: { SqrInputTextarea },
  mixins: [modal, input],
};
</script>
