export default {
  props: {
    show: Boolean,
  },
  computed: {
    modalClass() {
      return {
        'is-active': this.show,
      };
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
