<template>
  <div
    class="week-day-entry is-unselectable"
    style="border-left: 6px solid white"
    :style="{ 'border-left-color': entry.color }"
    v-if="entry"
  >
    <div class="week-day-entry-content">
      <div class="level is-mobile">
        <div class="level-left">
          <div class="level-item">
            <span class="icon">
              <fa :icon="['fal', entry.icon]" fixed-width />
            </span>
          </div>
          <div class="level-item">{{ name }}</div>

          <template v-if="entry.input === 'from-until'">
            <div class="level-item is-hidden-mobile">
              {{ $t('weekday_entry_from') }}
            </div>
            <div class="level-item">
              <input-time
                :value="entry.from"
                placeholder="entry_span_from"
                @
                @change="
                  entryChange({
                    date: entry.date,
                    id: entry.id,
                    field: 'from',
                    value: $event,
                  })
                "
                :disabled="disabled"
              />
            </div>
            <div class="level-item is-hidden-mobile">
              {{ $t('weekday_entry_until') }}
            </div>
            <div class="level-item">
              <input-time
                :value="entry.until"
                placeholder="entry_span_until"
                @change="
                  entryChange({
                    date: entry.date,
                    id: entry.id,
                    field: 'until',
                    value: $event,
                  })
                "
                :disabled="disabled"
              />
            </div>
          </template>

          <template v-else-if="entry.input === 'duration'">
            <input-duration-time
              :value="entry.duration"
              @change="
                entryChange({
                  date: entry.date,
                  id: entry.id,
                  field: 'duration',
                  value: $event,
                })
              "
              placeholder="entry_duration_placeholder"
              :disabled="disabled"
            />
          </template>

          <template
            v-else-if="entry.input === 'day' || entry.input === 'day-percent'"
          >
            <div class="level-item">
              <input-day
                :value="entry.day"
                @change="
                  entryChange({
                    date: entry.date,
                    id: entry.id,
                    field: 'day',
                    value: $event,
                  })
                "
                :disabled="disabled"
              />
            </div>
            <div class="level-item">{{ $t('weekday_entry_day') }}</div>
          </template>

          <!--<template v-else-if="entry.input === 'none'">-->
          <!--<div class="level-item">-->
          <!--<span style="max-width: 300px; word-break: keep-all; overflow: hidden;">{{entry.note}}</span>-->
          <!--</div>-->
          <!--</template>-->
        </div>
        <div class="level-right">
          <div class="level-item" v-if="!entry.valid">
            <span class="icon has-text-danger"
              ><fa :icon="['fal', 'exclamation-triangle']"
            /></span>
          </div>
          <div class="level-item" v-if="entry.generated">
            <span class="icon"><fa :icon="['fal', 'magic']" /></span>
          </div>
          <div class="level-item">
            <sqr-button
              icon="sticky-note"
              @click="noteModal = true"
              :disabled="disabled"
              :color="entry.note ? 'info' : ''"
              :is-outlined="!!entry.note"
              :tooltip="entry.note"
            />
          </div>
          <div class="level-item" v-if="canRemove">
            <sqr-button
              icon="trash"
              @click="entryRemove({ date: entry.date, id: entry.id })"
              :disabled="disabled"
            />
          </div>
        </div>
      </div>
    </div>

    <note-modal
      label="entry_note"
      :show="noteModal"
      :value="entry.note"
      @change="
        entryChange({
          date: entry.date,
          id: entry.id,
          field: 'note',
          value: $event,
        })
      "
      @close="noteModal = false"
    />
  </div>
</template>

<style scoped>
.week-day-entry {
  padding: 0.6rem 0 0.6rem 0;
  border-bottom: 1px solid #eee;
}

.week-day-entry-content {
  padding: 0 1.5rem 0 1.5rem;
}

.week-day-entry:last-child {
  border-bottom: none;
}
</style>

<script>
import SqrButton from '@/sqrd/SqrButton';
import InputTime from './InputTime';
import InputDay from './InputDay';
import InputDurationTime from './InputDurationTime';
import NoteModal from './NoteModal';

export default {
  name: 'WeekDayEntry',
  components: { SqrButton, InputDurationTime, InputDay, InputTime, NoteModal },
  props: {
    entry: Object,
    disabled: Boolean,
    canRemove: { type: Boolean, default: true },
  },
  data() {
    return { noteModal: false };
  },
  computed: {
    name() {
      let locale = this.$i18n.locale;
      return (
        (this.entry.name18 && this.entry.name18[locale]) || this.entry.name
      );
    },
  },
  methods: {
    entryChange(payload) {
      this.$emit('change', payload);
    },
    entryRemove(payload) {
      this.$emit('remove', payload);
    },
  },
};
</script>
