<template>
  <div>
    <label class="label" :class="labelClass" v-if="labelTr">{{
      labelTr
    }}</label>
    <div class="control" :class="controlClass" style="width: 4.5rem">
      <input
        :id="id"
        :name="name"
        :autocomplete="autocomplete"
        :placeholder="placeholderTr"
        class="input"
        :class="inputClass"
        type="text"
        :aria-describedby="nameDesc"
        :value="value"
        @input="input"
        @change="change"
        :disabled="disabled"
      />
      <span class="icon is-small is-left" v-if="icon"
        ><i class="fa" :class="iconClass"></i
      ></span>
      <span class="icon is-small is-right" v-if="valid"
        ><i class="fal fa-check"></i
      ></span>
      <p class="help" v-if="helper">{{ helperTr }}</p>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import input from '../sqrd/mixins/input';

export default {
  name: 'InputTime',
  mixins: [input],
  props: {
    guess: { type: String, default: '' },
  },
  methods: {
    parse(event) {
      let value = event.target.value;
      let parts = [];

      if (!value) {
        parts = [];
      } else if (value.length === 4) {
        parts = [value.substring(0, 2), value.substring(2, 4)];
      } else {
        parts = value.split(':');
      }

      let date;
      switch (parts.length) {
        case 0:
          return '';
        case 1:
          date = DateTime.fromObject({ hours: parseInt(parts[0]), minutes: 0 });
          break;
        default:
          date = DateTime.fromObject({
            hours: parseInt(parts[0]),
            minutes: parseInt(parts[1]),
          });
          break;
      }

      return date.toISOTime({ suppressSeconds: true, includeOffset: false });
    },
    input(event) {
      this.$emit('input', this.parse(event));
    },
    change(event) {
      let value = this.parse(event);
      this.$emit('change', value);
    },
  },
};
</script>
